<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  关联大币种    -->
      <div slot="itemTieUpBigCoinSlot" slot-scope="row">
        <a-tag color="blue" class="tag-class" v-for="item in row.coinList" :key="item.id">{{ item.coinKindName }}</a-tag>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <div class="flex-colum-start-center">
          <a-button
              class="mt-10"
              size="small"
              type="primary"
              @click="handleEditorSearchWord(row)"
          >编辑搜索词</a-button>
          <a-popconfirm
              title="确定删除当前搜索词？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleDeleteSearchWord(row)"
          >
            <a-button class="mt-10" size="small" type="danage">删除搜索词</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
<!--  编辑搜索词配置  -->
    <EditorSearchWordConfigPopup ref="editorSearchWordConfigPopupEl" @addOrEditorSuccess="handleEditorSuccess"/>
  </div>
</template>
<script>
import {coinSearchWordConfigColumns} from "@/views/cmsPage/coinManage/searchWordConfig/_data"
import {delSearchWordConfigApi} from "@/views/cmsPage/coinManage/_apis"
import EditorSearchWordConfigPopup
  from "@/views/cmsPage/coinManage/searchWordConfig/_components/EditorSearchWordConfigPopup/index.vue"

export default {
  props: ['pagination', 'list'],
  components: {EditorSearchWordConfigPopup},
  data() {
    return {
      tableList: coinSearchWordConfigColumns,
    }
  },
  computed: {
  },
  methods: {
    /** 编辑 */
    handleEditorSearchWord(row) {
      this.$refs.editorSearchWordConfigPopupEl.show(row)
    },
    /** 删除 */
    async handleDeleteSearchWord(row) {
      this.$loading.show()
      const res = await delSearchWordConfigApi(row.id)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleEditorSuccess()
    },
    /** 编辑成功 */
    handleEditorSuccess() {
      this.$emit('addOrEditorSuccess')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
.tag-class {
  margin-bottom: 5px;
}
</style>