var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemTieUpBigCoinSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                _vm._l(row.coinList, function(item) {
                  return _c(
                    "a-tag",
                    {
                      key: item.id,
                      staticClass: "tag-class",
                      attrs: { color: "blue" }
                    },
                    [_vm._v(_vm._s(item.coinKindName))]
                  )
                }),
                1
              )
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  { staticClass: "flex-colum-start-center" },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "mt-10",
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleEditorSearchWord(row)
                          }
                        }
                      },
                      [_vm._v("编辑搜索词")]
                    ),
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定删除当前搜索词？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDeleteSearchWord(row)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "mt-10",
                            attrs: { size: "small", type: "danage" }
                          },
                          [_vm._v("删除搜索词")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }
          }
        ])
      }),
      _c("EditorSearchWordConfigPopup", {
        ref: "editorSearchWordConfigPopupEl",
        on: { addOrEditorSuccess: _vm.handleEditorSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }