var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            staticClass: "w-200 mb-5",
            attrs: { allowClear: "", placeholder: "搜索词" },
            model: {
              value: _vm.params.word,
              callback: function($$v) {
                _vm.$set(_vm.params, "word", $$v)
              },
              expression: "params.word"
            }
          }),
          _c(
            "a-button",
            {
              staticClass: "mb-5",
              attrs: { type: "primary" },
              on: { click: _vm.search }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "a-button",
            { staticClass: "ml-20 mb-5", on: { click: _vm.handleAddConfig } },
            [_vm._v("新增配置")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("TableList", {
            attrs: { list: _vm.data, pagination: _vm.pagination },
            on: {
              changePage: _vm.handleChangePage,
              addOrEditorSuccess: _vm.getList
            }
          })
        ],
        1
      ),
      _c("EditorSearchWordConfigPopup", {
        ref: "editorSearchWordConfigPopupEl",
        on: { addOrEditorSuccess: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }