<template>
  <a-modal
      centered
      :width='1000'
      :title="title"
      v-model="showModal"
      @ok='handleConfirm'
      @cancel='handleCancel'
  >
    <div>
      <a-form-model v-bind="layout" :model='forms'>
        <a-form-model-item label='搜索词' prop='content'>
          <a-input placeholder='请输入需要配置的搜索词' v-model="forms.word"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="selectedCoinList && selectedCoinList.length" label='已选择' prop='content'>
          <template  v-for="item in selectedCoinList">
            <a-tag
                color="blue"
                closable
                :key="item.id"
                @close="handleDeleteTag($event, item)"
            >{{ item.coinKindName }}</a-tag>
          </template>
        </a-form-model-item>
        <a-form-model-item label='朝代检索大币种' prop='degree'>
          <a-select
              @search="searchDynastyData"
              v-model="dynastyId"
              closable
              :filter-option="untils.filterOption"
              @change="handleChangeSelectedDynasty"
              showSearch
              placeholder="请选择"
          >
            <a-select-option
                v-for="(item, index) of dynastyList"
                :key="index"
                :value="item.id"
            >{{ item.dynastyName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='检索大币种' prop='degree'>
          <a-select
              @search="searchCoinData"
              v-model="coinIds"
              :filter-option="untils.filterOption"
              showSearch
              mode="multiple"
              placeholder="请选择"
          >
            <a-select-option
                v-for="(item, index) of bigList"
                :key="index"
                :value="item.id"
            >{{ item.coinKindName }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import untils from "@/untils"
import {getBigCoinList, getBigCoinListByDynastyId, getDynastyListByName} from "@/axios/apis"
import {postSearchWordConfigEditorOrAddApi} from "@/views/cmsPage/coinManage/_apis"
export default {
  data() {
    return {
      untils,
      title: '添加搜索词配置',
      showModal: false,
      bigList: [],
      dynastyList: [],
      forms: {
        id: undefined,
        coinIds: undefined,
        word: undefined
      },
      coinIds: [],

      dynastyId: undefined,
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      },
      selectedCoinList: []
    }
  },
  methods: {
    async show(data) {
      if (data) {
        this.forms.id = data.id
        this.forms.word = data.word
        this.selectedCoinList = JSON.parse(JSON.stringify(data.coinList))
        this.title = '编辑搜索词配置'
      }
      await this.searchDynastyData()
      this.showModal = true
    },
    /** 删除已经选择的 */
    handleDeleteTag(e, item) {
      e.preventDefault();
      const foundIndex = this.selectedCoinList.findIndex(el => el.id === item.id)
      this.selectedCoinList.splice(foundIndex, 1)
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.forms.word) return this.$message.warn('请填写搜索词')
      if (this.coinIds.length < 1) return this.$message.warn('请选择关联的大币种')
      let tempCoinIds = []
      if (this.selectedCoinList.length) {
        tempCoinIds = [...new Set(this.coinIds), ...new Set(this.selectedCoinList.map((item) => item.id))]
      } else {
        tempCoinIds = this.coinIds
      }
      this.$loading.show()
      const res = await postSearchWordConfigEditorOrAddApi({
        id: this.forms.id,
        word: this.forms.word,
        coinIds: tempCoinIds.join(','),
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info(res.message)
      this.$emit('addOrEditorSuccess')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.resetForms()
      this.showModal = false
    },
    /** 重置表单 */
    resetForms() {
      this.forms = {
        id: undefined,
        coinIds: undefined,
        word: undefined
      }
      this.coinIds = []
      this.dynastyId = undefined
    },
    /** 改变朝代选择 */
    async handleChangeSelectedDynasty(e) {
      const res = await getBigCoinListByDynastyId(e)
      if (res.status !== '200') return
      this.bigList = res.data;
      this.coinIds = [...new Set(this.coinIds), ...new Set(res.data.map((item) => item.id))]
    },
    /** 搜索朝代 */
    async searchDynastyData(inner) {
      const res = await getDynastyListByName(inner)
      if (res.status !== "200") return
      this.dynastyList = res.data;
    },
    /** 搜索大币种 */
    async searchCoinData(inner) {
      const res = await getBigCoinList(inner)
      if (res.status !== "200") return
      const { records } = res.data;
      this.bigList = records;
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep .ant-form-item-control {
  line-height: 30px !important;
}

</style>